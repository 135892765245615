import React, { FC }     from "react"

import { PageMeta }                                    from "../components/common/site/PageMeta"
import { PageLayout }                                  from "../components/common/site/PageLayout"
import { MountingBracket }                             from "../components/common/MountingBracket"
import PrivacyPolicy                                   from "../components/common/site/PrivacyPolicy"

interface Props {
  data?: any,
}

const defaultProps: Props = {}

const PrivacyPage: FC<Props> = ( props ) => {
  
  return (
    <MountingBracket>
      
      <PageMeta />
      
      <PageLayout>

      <PrivacyPolicy />
      
      </PageLayout>
    </MountingBracket>
  )
}

PrivacyPage.defaultProps = defaultProps

export default PrivacyPage